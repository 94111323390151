<template>
  <a-form-model
    :model="form"
    layout="horizontal"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    ref="ruleForm"
    :colon="false"
    :rules="rules"
  >
    <a-form-model-item label="所属公司" prop="ptBcId">
      <a-select v-model="form.ptBcId" placeholder="请输入" disabled>
        <a-select-option
          v-for="item in GetCompanyList"
          :key="item.bc_id"
          :value="item.bc_id"
        >
          {{ item.bc_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="所属施工工序">
      <a-select
        v-model="form.wpId"
        placeholder="请输入"
        :disabled="recordType == 'edit'"
      >
        <a-select-option
          v-for="item in WorkProceList"
          :key="item.wpId"
          :value="item.wpId"
        >
          {{ item.wpName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="施工项">
      <a-select
        v-model="form.pdPId"
        placeholder="请输入"
        :disabled="recordType == 'edit'"
        show-search
        optionFilterProp="children"
      >
        <a-select-option
          v-for="item in ProjectList"
          :key="item.pid"
          :value="item.pid"
        >
          {{ item.pname }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="施工项套餐每平米占比">
      <a-tooltip placement="bottom">
        <template slot="title">
          <span
            >施工项套餐每平米占比=施工项套餐总面积使用的单位总数/套餐总面积</span
          >
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
      <a-input v-model="form.pdRatio" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="核算单位单价（元）">
      <a-tooltip placement="bottom">
        <template slot="title">
          <span>默认等于销售单价</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
      <a-input
        v-model="form.pdPrice"
        placeholder="请输入"
      /> </a-form-model-item
    ><a-form-model-item label="备注说明（选填）">
      <a-input v-model="form.pdDesc" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{
        span: 14,
        offset: 4,
      }"
    >
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 10px" @click="onClose"> 取消 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { GetCompanies } from "@/api/device";
import {
  GetWorkProceList,
  GetProjectList,
  GetPackageTypeList,
  DeleteWorkProce,
} from "@/api/apiJF/template";
export default {
  props: {
    record: {
      type: Object,
      default: {},
    },
    recordType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        ptBcId: "",
        wpId: "",
        pdPId: "",
        pdRatio: "",
        pdPrice: 0,
        pdBcId: "",
        pdDesc: "",
        pdWpId: "",
      },
      horizontal: "horizontal",
      GetCompanyList: {},
      CompanyListid: [],
      // 查询参数
      queryParam: {
        pdBcIds: "24",
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      rules: {
        ptBcId: [
          {
            required: true,
            message: "请选择所属公司名称",
            trigger: "change",
          },
          // {
          //   min: 3,
          //   max: 5,
          //   message: "Length should be 3 to 5",
          //   trigger: "blur",
          // },
        ],
      },
      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      emitForm: "",
    };
  },
  computed: {},
  created() {
    this.GetCompaniesId();
    // this.form.ptBcId = this.record.ptBcId;
    console.log("record", this.record);
    this.form.ptBcId = this.record.ptBcId;
    if (this.record.pdWpId) {
      this.form.wpId = this.record.pdWpId;
      if (this.recordType == "edit") {
        // this.form.pdPId = this.record.pdPId;
        // this.form = this.record;
        this.form.pdPId = this.record.pdPId;
        this.form.pdDesc = this.record.pdDesc;
        this.form.pdPrice = this.record.pdPrice;
        this.form.pdRatio = this.record.pdRatio;
        this.form.pdBcId = this.record.ptBcId;
        this.form.pdType = "1";
        this.form.pdWpId = this.record.wpId;
        // this.form.tbranchcompany.bcId = this.record.ptBcId;
        this.form.wpId = this.record.pdWpId;
      }
    } else if (this.record.wpId) {
      this.form.wpId = this.record.wpId;
    }
  },
  watch: {
    "form.ptBcId": {
      handler(newValue) {
        // // 获取施工工序
        this.GetWorkProceList(newValue);
        // 获取施工项
        this.GetProjectList(newValue);
      },
    },
    "form.pdPId": {
      handler(newValue) {
        this.ProjectList.forEach((el) => {
          if (newValue == el.pid) {
            this.form.pdPrice = el.psalesPrice;
          }
        });
      },
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            // this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.pdBcIds = this.CompanyListid.toString();
          // // 获取施工工序
          // this.GetWorkProceList();
          // 获取施工项
          // this.GetProjectList();
          // // 获取套餐类别
          // this.GetPackageTypeList();
        });
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params).then((res) => {
        // console.log("施工工序", res.data);
        this.WorkProceList = res.data;
        // console.log("this.WorkProceList", this.WorkProceList);
      });
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        // console.log("施工项", res.data);
        this.ProjectList = [];
        res.data.forEach((el) => {
          this.ProjectList.push(el);
          if (el.children) {
            el.children.forEach((item) => {
              this.ProjectList.push(item);
            });
          }
        });
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
          // console.log("form", this.form);
          this.changeFormType(this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeFormType(form) {
      let data = {
        wpId: form.wpId,
        children: [
          {
            pdPId: form.pdPId,
            pdDesc: form.pdDesc,
            pdPrice: form.pdPrice,
            pdRatio: form.pdRatio,
            pdBcId: form.ptBcId,
            pdType: "1",
            pdWpId: form.wpId,
            tbranchcompany: {
              bcId: form.ptBcId,
            },
          },
        ],
      };
      this.emitForm = JSON.stringify(data);
      this.$emit("tabledataChange", this.emitForm);
    },
    handleChange() {
      this.$forceUpdate();
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/.anticon {
  margin-right: 5px;
}
/deep/ .ant-form-item-children {
  display: flex;
  align-items: center;
  justify-content: right;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>