<template>
  <div>
    <!-- 基本信息 -->
    <div class="basicInfo">
      <div class="infoTit">
        <div>基本信息</div>
        <!-- <div @click="showDrawer"><a>编辑</a></div> -->
      </div>
      <div v-if="viewShow" class="basicInfo_view">
        <div>所属公司：{{ CompanyListResult(form.ptBcId) }}</div>
        <div>套餐编号：{{ form.ptNumber }}</div>
        <div>套餐类别：{{ PackageTypeListResult(form.ptPtId) }}</div>
        <div class="basicInfo_view_hidden">
          套餐名称：<a-tooltip>
            <template slot="title">
              {{ form.ptName }}
            </template>
            {{ form.ptName }}
          </a-tooltip>
        </div>
        <div>报价单位：{{ form.ptUnit }}</div>
        <div>单价(元)：{{ form.ptPrice }}</div>
        <div class="basicInfo_view_hidden">
          备注说明：
          <a-tooltip>
            <template slot="title">
              {{ form.ptDesc }}
            </template>
            {{ form.ptDesc }}
          </a-tooltip>
        </div>
      </div>
      <div v-if="!viewShow">
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="所属公司">
            <a-select
              v-model="form.ptBcId"
              placeholder="请输入"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in GetCompanyList"
                :key="item.bc_id"
                :value="item.bc_id"
              >
                {{ item.bc_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="套餐编号">
            <a-input v-model="form.ptNumber" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="套餐类别">
            <!-- <a-select
              v-model="form.ptPtId"
              placeholder="请输入"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in PackageTypeList"
                :key="item.ptId"
                :value="item.ptId"
              >
                {{ item.ptName }}
              </a-select-option>
            </a-select> -->
            <a-tree-select
              v-model="form.ptPtId"
              style="width: 150px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="PackageTypeList"
              tree-default-expand-all
              :replaceFields="{
                key: 'ptId',
                title: 'ptName',
                value: 'ptId',
                children: 'children',
              }"
            >
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="套餐名称">
            <a-input v-model="form.ptName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="报价单位">
            <a-input v-model="form.ptUnit" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="单价(元)">
            <a-input
              v-model="form.ptPrice"
              placeholder="请输入"
              @change="ptPriceChange"
            />
            <!--     @blur="ptPriceChange" -->
          </a-form-model-item>
          <!-- <a-form-model-item label="套餐概要">
            <a-textarea v-model="form.fieldB" placeholder="请输入" auto-size />
          </a-form-model-item> -->
          <a-form-model-item label="备注说明(选填)">
            <a-textarea
              v-model="form.ptDesc"
              placeholder="请输入"
              :auto-size="{ minRows: 1, maxRows: 3 }"
            />
          </a-form-model-item>
          <!-- <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
            <a-button type="primary"> 保存 </a-button>
            <a-button style="margin-left: 10px" @click="onClose">
              取消
            </a-button>
          </a-form-model-item> -->
        </a-form-model>
      </div>
    </div>
    <!-- 详细信息 -->
    <div>
      <div class="infoTit">详细信息</div>
      <div class="tableHeight">
        <!--       :scroll="{ y: 550 }"
          tableLayout="fixed" -->
        <div class="addBtn" v-if="!viewShow">
          <a-button
            type="primary"
            @click="showDrawer('BuildPackageEdit', '新增', 'add')"
            >新增</a-button
          >
        </div>
        <a-table
          ref="table"
          size="small"
          :rowKey="
            (record) => {
              return record.wpId ? record.wpId : record.pdPId;
            }
          "
          :columns="columns"
          :data-source="tabledata"
          :showPagination="false"
          :scroll="{ x: '1000px', y: '500px ' }"
          :defaultExpandAllRows="true"
          v-if="tableShow"
        >
          <span slot="wpId" slot-scope="text">
            <div>{{ WorkProceResult(text) }}</div>
          </span>
          <!-- <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            {{ record.children }}
          </p> -->
          <span slot="pdPId" slot-scope="text">
            <div>{{ text ? ProjectListResult(text) : "" }}</div>
          </span>

          <span slot="tbranchcompany" slot-scope="text">
            <div>{{ text ? CompanyListResult(text.bcId) : "" }}</div>
          </span>
          <!-- 操作 -->
          <span slot="action" slot-scope="text, record" class="action">
            <a
              class="editBtn"
              v-if="record.pdPId"
              @click="showDrawer('BuildPackageEdit', '修改', 'edit', record)"
              >修改</a
            >
            <a
              class="editBtn"
              type="primary"
              @click="showDrawer('BuildPackageEdit', '新增', 'add', record)"
              >新增</a
            >
            <a-popconfirm
              title="确定删除此条施工项信息吗?"
              @confirm="() => onDelete(record, text)"
            >
              <a class="delName" v-if="record.pdPId">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
      <div class="btnsDisplayflex">
        <div>额外收费项：</div>
        <div v-if="viewShow" class="btnsDisplayflex_inner">
          <div v-for="(item, index) in this.NewExtraCharge" :key="index">
            {{ item.pdPName }}
          </div>
        </div>
        <a-checkbox-group
          @change="changeradioBtn"
          v-model="NewExtraChargeValue"
          v-if="!viewShow"
        >
          <a-row>
            <a-col :span="3" v-for="el in ExtraChargeList" :key="el.ecId">
              <a-checkbox :value="el.ecId"> {{ el.ecName }} </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>

      <div>
        <div v-if="viewShow">
          <a-button type="primary" @click="showEdit()">编辑</a-button>
          <a-button @click="cancellClick()" style="margin-left: 20px"
            >返回</a-button
          >
        </div>
        <div v-if="!viewShow">
          <a-button type="primary" @click="submitClick()">提交</a-button>

          <a-button @click="cancellClick()" style="margin-left: 20px"
            >取消</a-button
          >
        </div>
      </div>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <BasicinfoEdit
        @onClose="onClose"
        :record="record"
        :recordType="recordType"
        ref="BasicinfoEdit"
        @tabledataChange="($event) => tabledataChange()"
      />
    </a-drawer>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { STable, SSearch, ColumnsSet } from "@/components";

import {
  GetPackageDetail,
  AddPackageTemplate,
  UpdatePackageTemplate,
  DeletePackageDetail,
  AddPackageDetail,
  UpdatePackageDetail,
  ComputePackageDetail,
} from "@/api/apiJF/package";
import {
  GetWorkProceList,
  GetPackageTypeList,
  DeleteWorkProce,
  GetProjectList,
  GetExtraChargeList,
} from "@/api/apiJF/template";
import BasicinfoEdit from "./BasicinfoEdit.vue";

export default {
  name: "BuildPackage",
  components: { BasicinfoEdit, STable },
  data() {
    return {
      viewShow: true, //是否显示详情
      queryParam: {
        pdPtId: "", //模版ID
        pdBcId: "18",
      },
      tableShow: false,
      DrawerTitle: "",
      GetCompanyList: {},
      CompanyListid: [],
      CompanyList: {},
      PackageTypeListId: {}, //套餐类别
      PackageTypeList: [],
      columns: [
        {
          title: "施工工序",
          dataIndex: "wpId",
          key: "wpId",
          width: "300px",
          // align: "left",
          scopedSlots: { customRender: "wpId" },
        },
        {
          title: "施工项",
          dataIndex: "pdPId",
          key: "pdPId",
          width: "300px",
          // align: "left",
          scopedSlots: { customRender: "pdPId" },
        },

        {
          title: "公司名称",
          dataIndex: "tbranchcompany",
          key: "tbranchcompany",
          width: "200px",
          // align: "left",
          scopedSlots: { customRender: "tbranchcompany" },
        },
        {
          title: "施工项套餐每平米占比",
          dataIndex: "pdRatio",
          key: "pdRatio",
          width: "150px",
          align: "center",
        },
        {
          title: "核算单位单价(元)",
          dataIndex: "pdPrice",
          key: "pdPrice",
          width: "150px",
          align: "center",
        },
        {
          title: "套餐优惠单价(元)",
          dataIndex: "pdCheapPrice",
          key: "pdCheapPrice",
          customRender: (text, record) => {
            let price = text ? record.pdPrice - text : "";
            return text ? Math.round(price * 100) / 100 : "";
          },
          width: "150px",
          align: "center",
        },
        {
          title: "备注",
          dataIndex: "pdDesc",
          key: "pdDesc",
          width: "150px",
          align: "center",
        },
      ],
      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      ExtraChargeList: [], //额外收费项
      visibleDrawer: false,
      form: {
        ptBcId: "", //分公司id
        ptNumber: "", //编号
        ptPtId: "", //类型
        ptName: "", //名称
        ptDesc: "", //说明
        ptPrice: "", //单价
        ptUnit: "", //报价单位
      },
      tabledata: [],
      record: {
        ptBcId: "",
      },
      NewExtraCharge: [],
      NewExtraChargeValue: [],
      isShowcancellBtn: true,
      recordType: "",
    };
  },
  created() {
    this.GetCompaniesId();
    // console.log("this.$route.params", this.$route);
    // 路由传参，循环赋值
    let params = this.$route.params.record;
    if (params) {
      if (Object.keys(params).length != 0) {
        for (let el in this.form) {
          this.form[el] = params[el];
        }

        this.queryParam.pdPtId = params.ptId;
        this.queryParam.pdBcId = params.ptBcId;
        this.record.ptBcId = this.$route.params.record.ptBcId;

        // 如果是编辑 则查询详细信息
        if (this.$route.params.type == "edit") {
          this.loadData();
          this.$route.meta.title = "套餐详情";
        } else {
          this.viewShow = false;
          this.columns.push({
            title: "操作",
            key: "action",
            scopedSlots: { customRender: "action" },
            width: "200px",
            align: "center",
            fixed: "right",
          });
          this.$route.meta.title = "新增套餐";
        }
      }
    }
  },
  mounted() {
    this.tableShow = true;
  },
  computed: {},
  watch: {
    "form.ptBcId": {
      handler(newValue) {
        this.tabledata = [];
        if (newValue) {
          // 获取套餐类别
          this.GetPackageTypeList(newValue);
          this.record.ptBcId = newValue;
          // 获取额外收费项
          this.GetExtraChargeList(newValue);
          // 获取施工工序
          this.GetWorkProceList(newValue);
          // 获取施工项
          this.GetProjectList(newValue);
        }
      },
    },
    tabledata: {
      handler(newValue) {
        console.log("表格修改", newValue);
      },
      deep: true,
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.ptBcId = this.CompanyListid.toString();
          // this.GetList();
          // // 获取施工工序
          // this.GetWorkProceList();
          // // 获取套餐类别
          this.GetPackageTypeList();
          // // 获取施工项
          // this.GetProjectList();
        });
    },
    // 根据分公司id查询分公司名称
    CompanyListResult(a) {
      return this.CompanyList[a];
    },
    // 查询套餐详细信息
    loadData() {
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "") {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageDetail(params).then((res) => {
        // console.log("res", res.data);
        this.tabledata = res.data.project;
        this.tabledata.forEach((el) => {
          el.children.forEach((item) => {
            item.childrenWpId = el.wpId;
          });
        });
        let extra = res.data.extra;
        console.log("extra", extra);
        extra.forEach((item) => {
          // console.log(item);
          this.NewExtraChargeValue.push(item.textraCharges.ecId);
          this.NewExtraCharge.push({
            pdBcId: this.form.ptBcId,
            pdType: "2",
            pdPId: item.textraCharges.ecId,
            pdPName: item.textraCharges.ecName,
            pdPtId: item.pdPtId,
          });
        });
      });
      // this.tabledata = data;
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params).then((res) => {
        // console.log("施工工序", res.data);
        res.data.forEach((el) => {
          this.WorkProceList[el.wpId] = el.wpName;
          // 如果是新增 则展示所有施工工序
          if (this.$route.params.type == "add") {
            this.tabledata.push({
              wpId: el.wpId,
              children: [],
            });
          }
        });
        // console.log("施工工序", this.WorkProceList, this.tabledata);
      });
    },
    // 根据施工工序id查询施工工序名称
    WorkProceResult(a) {
      return this.WorkProceList[a];
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        // console.log("施工项", res.data);
        res.data.forEach((el) => {
          this.ProjectList[el.pid] = el.pname;
          if (el.children) {
            el.children.forEach((item) => {
              this.ProjectList[item.pid] = item.pname;
            });
          }
        });
      });
    },
    // 根据施工项id查询施工工序名称
    ProjectListResult(a) {
      return this.ProjectList[a];
    },
    // 获取套餐类别
    GetPackageTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageTypeList(params).then((res) => {
        this.PackageTypeList = res.data;
        res.data.forEach((el) => {
          this.PackageTypeListId[el.ptId] = el.ptName;
          el.children.forEach((item) => {
            this.PackageTypeListId[item.ptId] = item.ptName;
          });
        });
      });
    },
    // 根据套餐类别id查询套餐名称
    PackageTypeListResult(a) {
      return this.PackageTypeListId[a];
    },

    // 点击编辑，隐藏详情  显示编辑页面
    showEdit() {
      this.$route.meta.title = "套餐编辑";
      this.viewShow = false;
      this.columns.push({
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        align: "center",
        fixed: "right",
      });
      // this.columns = columns;
    },
    afterVisibleChange(val) {},
    // 展开抽屉
    showDrawer(name, title, type, record) {
      this.visibleDrawer = true;
      this.DrawerTitle = title;
      if (record) {
        this.record = record;
      }
      this.recordType = type;
      // console.log("showDawer", record);
      this.record.ptBcId = this.form.ptBcId;
    },
    // 查询额外收费项列表
    GetExtraChargeList(ecBcIds) {
      let queryParam = {
        ecBcIds: ecBcIds ? ecBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ecBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetExtraChargeList(params).then((res) => {
        this.ExtraChargeList = res.data;
      });
    },
    // 额外收费项的选择
    changeradioBtn(e) {
      // pdBcId 分公司id  pdPId 额外收费项id
      //pdType 类型2  pdPrice价格   pdPtId模版id
      console.log("额外收费项的选择", e);
      this.NewExtraCharge = [];
      e.forEach((el) => {
        this.NewExtraCharge.push({
          pdBcId: this.form.ptBcId,
          pdType: "2",
          pdPId: el,
          // pdPtId: this.form.ptBcId,
        });
      });
      // console.log("this.NewExtraCharge", this.NewExtraCharge);
      console.log("this.queryParam.pdPtId", this.queryParam.pdPtId);
      // this.NewExtraCharge.pdBcId = this.form.ptBcId;
      // this.NewExtraCharge.pdType = "2";
      // this.NewExtraCharge.pdPId = e;
    },
    onClose() {
      this.visibleDrawer = false;
    },
    tabledataChange() {
      let emitForm = JSON.parse(this.$refs.BasicinfoEdit.emitForm);
      console.log(" emitForm", emitForm);
      let tabledata = this.tabledata;
      if (tabledata.length == 0) {
        this.onClose();
        tabledata.push(emitForm);
      } else {
        // 下标大于-1   证明存在

        let findIndex = tabledata.findIndex((item) => {
          return item.wpId == emitForm.wpId;
        });
        if (findIndex > -1) {
          tabledata.forEach((el, index) => {
            if (el.wpId == emitForm.wpId) {
              // el.children.push(emitForm.children[0]);
              // this.onClose();
              let pdpIdindex = el.children.findIndex((item) => {
                return item.pdPId == emitForm.children[0].pdPId;
              });
              // console.log("pdpIdindex", pdpIdindex);
              if (pdpIdindex == -1) {
                el.children.push(emitForm.children[0]);
                this.onClose();
              } else {
                if (this.recordType == "add") {
                  this.$message.error("施工项重复设置");
                } else {
                  el.children[pdpIdindex] = emitForm.children[0];
                  this.onClose();
                }
              }
            } else {
              // this.$message.error("施工项重复设置");
            }
          });
        } else {
          tabledata.push(emitForm);
          this.onClose();
        }
      }
      this.tabledata = tabledata;
      this.ComputetabledataChange(this.form.ptPrice, this.tabledata);
    },
    // 提交按钮
    submitClick() {
      // console.log("form", this.form);
      if (this.$route.params.type == "edit") {
        this.UpdatePackageTemplate();
        this.UpdatePackageDetail();
      } else {
        // 新建基本信息
        this.AddPackageTemplate();
      }
    },
    // 新建套餐基本信息
    AddPackageTemplate() {
      let params = this.form;
      AddPackageTemplate(params).then((res) => {
        // console.log(res.data);
        this.AddPackageDetail(res.data);
      });
    },
    // 修改基本信息
    UpdatePackageTemplate() {
      let params = this.form;
      params.ptId = this.queryParam.pdPtId;
      // console.log("this.form", this.form);
      UpdatePackageTemplate(params).then((res) => {
        // console.log(res);
      });
    },
    onDelete(record, text) {
      console.log("delete", record, text);
      const tabledata = [...this.tabledata];
      // this.tabledata = tabledata.filter(item => item.key !== record.pdPId);

      tabledata.forEach((item) => {
        let newchildren = [];
        if (item.wpId == record.pdWpId) {
          item.children.forEach((el) => {
            if (el.pdPId != record.pdPId) {
              newchildren.push(el);
              console.log(el.pdPId);
            }
          });
          item.children = newchildren;
        }
      });
      this.tabledata = tabledata;
      this.ComputetabledataChange(this.form.ptPrice, tabledata);
    },
    // 新建套餐详细信息
    AddPackageDetail(pdPtId) {
      let params = this.tabledata;
      // console.log("新建套餐详细信息", params);
      let list = [];
      params.forEach((el) => {
        el.children.forEach((item, index) => {
          item.pdWpId = el.wpId;
          item.pdPtId = pdPtId;
          item.pdBcId = this.form.ptBcId;
          item.pdOrder = index;
          list.push(item);
        });
      });
      // 额外收费项
      if (this.NewExtraCharge.length > 0) {
        this.NewExtraCharge.forEach((item) => {
          item.pdPtId = pdPtId;
          list.push(item);
        });
        // list.push(this.NewExtraCharge);
      }
      // console.log("list", list);
      AddPackageDetail(list).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("新建套餐成功");
          this.isShowcancellBtn = false;
          this.$router.push({
            path: "/PackageTemplate",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改套餐详细信息
    UpdatePackageDetail(pdPtId) {
      let params = this.tabledata;
      // console.log("更新套餐详细信息", params);
      // console.log(" this.queryParam.pdPtId", this.queryParam.pdPtId);
      let list = [];
      params.forEach((el) => {
        el.children.forEach((item, index) => {
          item.pdWpId = el.wpId; //施工工序id
          item.pdPtId = this.queryParam.pdPtId; //模版id
          item.pdBcId = this.form.ptBcId; //分公司id
          item.pdOrder = index;
          list.push(item);
        });
      });
      // 额外收费项
      if (this.NewExtraCharge.length > 0) {
        this.NewExtraCharge.forEach((item) => {
          item.pdPtId = this.queryParam.pdPtId;
          list.push(item);
        });
        // list.push(this.NewExtraCharge);
      }
      console.log("list", list);
      UpdatePackageDetail(list).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改套餐成功");
          this.isShowcancellBtn = false;
          this.$router.push({
            path: "/PackageTemplate",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳转到套餐列表页面
    cancellClick() {
      this.$router.push({
        path: "/PackageTemplate",
      });

      this.columns.filter((el) => {
        return el.key == "action";
      });
    },
    // 表格内施工项改变，计算施工项内的信息
    ComputetabledataChange(ptPrice, tabledata) {
      let params = {
        ptPrice: ptPrice,
      };
      let data = tabledata;
      ComputePackageDetail(params, data).then((res) => {
        console.log("ComputePackageDetail", res.data);
        this.tabledata = res.data;
      });
    },
    // 单价改变
    ptPriceChange(e) {
      console.log("单价", e.target.value);
      this.ComputetabledataChange(e.target.value, this.tabledata);
    },
  },
};
</script>

<style lang="less" scoped>
.infoTit {
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.basicInfo {
  text-align: left;

  .displayFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .margin_top {
    margin-top: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.margin_right {
  margin-right: 10px;
}
.btnsDisplayflex {
  margin-bottom: 20px;
  display: flex;
  div {
    margin-right: 20px;
    font-weight: 800;
  }
  /deep/ .ant-btn {
    margin-right: 20px;
  }
  .btnsDisplayflex_inner {
    display: flex;
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
.editBtn {
  margin-right: 20px;
}
.delName {
  color: red;
}
.addBtn {
  text-align: left;
  margin-top: 20px;
}
// /deep/.ant-table-thead > tr {
//   height: 96px !important;
// }
/deep/ .ant-table-tbody > tr {
  height: 90px;
}
/deep/.ant-checkbox-group {
  width: 80%;
  text-align: left;
}
.basicInfo_view {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 300px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .basicInfo_view_hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
